<script setup>
import { ref, computed, nextTick, onMounted } from '#imports';
import { Field } from 'vee-validate';
import { useVModel } from '@vueuse/core';

import useForm from '@/composables/useForm';

const formComposable = useForm();

const props = defineProps({
  label: {
    type: String,
    default: '',
  },
  modelValue: {
    type: [String, Number],
    default: '',
  },
  isDisabled: {
    type: Boolean,
    default: false,
  },
  placeholder: {
    type: String,
    default: '',
  },
  showErrors: {
    type: Boolean,
    default: true,
  },
  isRequired: {
    type: Boolean,
    default: false,
  },
  autoHeight: {
    type: Boolean,
    default: false,
  },
  maxLength: {
    type: Number,
    default: 1000,
  },
  maxHeight: {
    type: Number,
    default: undefined,
  },
  minHeight: {
    type: Number,
    default: undefined,
  },
  name: {
    type: String,
    default: '',
  },
});

const emit = defineEmits(['update:modelValue', 'blur', 'update-form-height']);
const localVModel = useVModel(props, 'modelValue', emit);

const focused = ref(false);
const textareaItem = ref(false);
const prevScrollHeight = ref(0);

function setFocus(value) {
  focused.value = Boolean(value);
}

//<editor-fold desc="Auto height">
const contentHeight = ref(0);

const textareaHeightCss = computed(() => {
  return `${toEm(contentHeight.value)}`;
});
const textareaMaxHeightCss = computed(() => {
  return typeof props.maxHeight === 'number' ? toEm(props.maxHeight) : 'none';
});
const textareaMinHeightCss = computed(() => {
  return typeof props.minHeight === 'number' ? toEm(props.minHeight) : 'auto';
});

function setTextareaHeight() {
  if (!props.autoHeight) return;

  if (prevScrollHeight.value === textareaItem.value?.scrollHeight) return;

  contentHeight.value = 0;

  nextTick(() => {
    prevScrollHeight.value = textareaItem.value?.scrollHeight;
    contentHeight.value = textareaItem.value?.scrollHeight;

    emit('update-form-height', contentHeight.value);
  });
}

function toEm(value) {
  return `${value / 16}em`;
}

onMounted(() => {
  setTextareaHeight();
});
//</editor-fold>

//<editor-fold desc="Availability">
const randomId = formComposable.getRandomCode('input');
const randomErrorId = formComposable.getRandomCode('error');

const isAriaInvalid = computed(() => Boolean(props.showErrors));
const ariaDescribedby = computed(() => `${randomErrorId}`);
//</editor-fold>
</script>

<template>
  <Field
    v-slot="{ field, errorMessage }"
    v-model="localVModel"
    :name="name"
    as="div"
  >
    <div
      class="ui-textarea"
      :class="{
        'ui-textarea--inner-indent': showErrors,
        'ui-textarea--disabled': isDisabled,
        'ui-textarea--error': !!errorMessage,
        'ui-textarea--focused': focused,
        'ui-textarea--active': modelValue,
        'ui-textarea--auto-height': autoHeight,
      }"
    >
      <div class="ui-textarea__header">
        <label v-show="label" :for="randomId" class="ui-textarea__label">
          <span class="ui-textarea__font ui-textarea__font--label">
            {{ label }}
          </span>
        </label>
      </div>

      <div class="ui-textarea__body">
        <div
          :style="{
            '--textarea-height': textareaHeightCss,
            '--max-form-height': textareaMaxHeightCss,
            '--min-form-height': textareaMinHeightCss,
          }"
          class="ui-textarea__filed-wrapper"
        >
          <textarea
            :id="randomId"
            v-bind="field"
            ref="textareaItem"
            :placeholder="placeholder"
            :maxlength="maxLength"
            class="ui-textarea__field"
            :name="name"
            autocomplete="off"
            :required="isRequired"
            :disabled="isDisabled"
            :aria-describedby="ariaDescribedby"
            :aria-required="isRequired"
            :aria-invalid="isAriaInvalid"
            @input="setTextareaHeight"
            @focus="setFocus(true)"
            @blur="setFocus(false)"
          />
        </div>

        <div class="ui-textarea__length">
          <span class="ui-textarea__font ui-textarea__font--length">
            {{ modelValue.length }}/{{ props.maxLength - 1 }} символов
          </span>
        </div>
      </div>

      <span
        v-show="showErrors && errorMessage"
        class="ui-textarea__footer"
        :title="errorMessage"
      >
        <span
          :id="randomErrorId"
          aria-live="assertive"
          class="ui-textarea__font ui-textarea__font--error"
        >
          {{ errorMessage }}
        </span>
      </span>
    </div>
  </Field>
</template>

<style scoped lang="scss">
.ui-textarea {
  $parent: &;
  $font-family: $font-family-default;
  $color-basis: $color-black;
  $color-label: $color-grey-3;
  $color-label-active: $color-grey-3;
  $color-placeholder: $color-grey-3;
  $color-placeholder-focus: $color-grey-2;
  $color-border: $color-black;
  $color-border-active: $color-black;
  $color-error: $color-red-1;
  $color-hover: $color-black;
  $color-bg: $color-white;
  $inner-indent: 24;
  $font-size: 18;

  --max-form-height: #{em(120)};
  --textarea-height: auto;
  --min-form-height: auto;

  position: relative;

  &__font {
    &--label {
      font-size: em(12);
      font-weight: 400;
      line-height: 1;
    }

    &--error {
      @include text-overflow;

      font-size: em(13);
      font-weight: 400;
      line-height: 1.2;
      color: $color-error;
    }

    &--length {
      font-size: em(12);
      font-weight: 400;
      line-height: 1;
      color: $color-label;
    }
  }

  &__header {
  }

  &__body {
    position: relative;
    display: block;
    background: transparent;
    border: 1px solid rgba($color-border, 0.1);
    border-radius: em(24);

    &::before {
      position: absolute;
      top: 1px;
      right: 1px;
      left: 1px;
      display: block;
      height: em(28);
      content: '';
      background-color: $color-bg;
      border-radius: em(24) em(24) 0 0;
      opacity: 0;
    }

    @include hover {
      border-color: rgba($color-border-active, 0.35);
    }
  }

  &__length {
    padding-top: em(8);
    padding-right: em(24);
    padding-bottom: em(16);
    text-align: right;
  }

  &__footer {
    position: absolute;
    bottom: em(2);
    left: 0;
    display: flex;
    align-items: center;
    width: 100%;
    padding-left: em(24);
    text-align: left;
  }

  &__label {
    position: absolute;
    top: em(12);
    left: em(24);
    z-index: 1;
    display: block;
    color: $color-label;
    pointer-events: none;
    touch-action: none;
    cursor: auto;
    opacity: 0;
    transition: all 0.3s;
    transform: translateY(em(7));
  }

  &__filed-wrapper {
    height: var(--textarea-height);
    min-height: var(--min-form-height);
    max-height: var(--max-form-height);
  }

  &__field {
    @include hide-scroll;

    width: 100%;
    height: 100%;
    padding: em(20, $font-size) em(24, $font-size) 0;
    font-size: em($font-size);
    font-weight: 500;
    line-height: 1.3;
    color: $color-basis;
    resize: none;
    background-color: $color-bg;
    border: none;
    border-radius: em(24, $font-size);
    transition: all 0.3s;

    &:focus,
    &:active {
    }

    &::placeholder {
      color: $color-placeholder;
      transition: all 0.3s;
      position: relative;
      z-index: 2;
    }
  }

  &--error {
    #{$parent} {
      &__body {
        border-color: $color-error;

        @include hover {
          border-color: rgba($color-border-active, 0.35);
        }
      }
    }
  }

  &--inner-indent {
    padding-bottom: em($inner-indent);
  }

  &--focused {
    #{$parent} {
      &__body {
        border-color: rgba($color-border-active, 0.35);
        &::before {
          opacity: 1;
        }
      }

      &__field {
        padding: em(38, $font-size) em(24, $font-size) 0;

        &::placeholder {
          color: $color-placeholder-focus;
        }
      }

      &__label {
        color: $color-label-active;
        pointer-events: auto;
        touch-action: auto;
      }
    }
  }

  &--active {
    #{$parent} {
      &__body {
        border-color: rgba($color-border-active, 0.35);
        &::before {
          opacity: 1;
        }
      }

      &__field {
        padding: em(38, $font-size) em(24, $font-size) 0;
      }

      &__label {
        color: $color-label-active;
        pointer-events: auto;
        touch-action: auto;
        opacity: 1;
        transform: translateY(0);
      }
    }
  }

  &--disabled {
    #{$parent} {
      &__body {
        @include hover {
        }

        &:focus,
        &:active {
          &::placeholder {
          }
        }
      }
    }
  }

  &--auto-height {
    #{$parent} {
      &__field {
        overflow-y: auto;
      }
    }
  }
}
</style>
